
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class Articles extends Vue {
  @Prop({})
    specificArticlesData?: {
    title: string
    imgUrl: string
    url: string
    snippet: string
  }[]

  @Prop({ default: false })
    titlesOnly: boolean

  generalArticlesData = [
    {
      title: 'Camping guides',
      snippet:
        "We've been helping campers find their perfect pitch for more than 12 years now. But our experience goes far beyond that.",
      url: '/guides/',
      imgUrl: require('@/assets/homepage/guides/guide.jpg'),
    },
    {
      title: 'The ultimate NC500 itinerary',
      snippet:
        "The NC500 - also known as the North Coast 500 - is a 516-mile journey around Scotland's north coast and one of the Pitchup team's favourite UK road trips",
      url: '/camping-ideas/activities/road-trips/NC500/',
      imgUrl: require('@/assets/homepage/guides/nc500.jpg'),
    },
    {
      title: 'Camping ideas',
      snippet:
        "Are you a confident camper, or a newbie who's planning a first outdoor stay? Either way, this page is all about inspiring you to get",
      url: '/camping-ideas/',
      imgUrl: require('@/assets/homepage/guides/camping-ideas.jpg'),
    },
    {
      title: 'Camping with a dog',
      snippet:
        "So you want to take your dog on a camping break? First things first - you'll need to pick a campsite that welcomes dogs",
      url: '/guides/camping/types-of-camping/camping-with-a-dog/',
      imgUrl: require('@/assets/homepage/guides/dogs.jpg'),
    },
    {
      title: 'Best campsites UK',
      snippet:
        'Short on time? Our team has hand-picked the best campsites for your next camping trip to help you whittle down where to go',
      url: '/camping-ideas/best-campsites/',
      imgUrl: require('@/assets/homepage/guides/best-campsites-uk.jpg'),
    },
    {
      title: "Wild camping: a beginner's guide",
      snippet:
        "Wild camping can be an incredibly peaceful and even spiritual experience, but it takes a bit of planning to make sure you're doing it in a safe and legal way",
      url: '/guides/camping/types-of-camping/wild-camping/',
      imgUrl: require('@/assets/homepage/guides/wild.jpg'),
    },
    {
      title: 'Campfires',
      snippet:
        'A campfire is one of the very best bits about going camping. It is the focal point of your camping pitch, you can cook on it',
      url: '/guides/camping/campfires/',
      imgUrl: require('@/assets/homepage/guides/campfires.jpg'),
    },
    {
      title: 'National Parks',
      snippet:
        "From The Broads wetlands to the granite tors of Dartmoor, the UK's incredible national parks are our outdoor playgrounds.",
      url: '/area-guides/national-parks/',
      imgUrl: require('@/assets/homepage/guides/national-parks.jpg'),
    },
  ]
}
