import { assign, createMachine, Interpreter, spawn, State } from 'xstate'
import { GeoPoint } from '~/utility/geo/GeoLocation'
import { reportError } from '~/utility/reportError'
import { LocationWatcher } from '../location-watcher/LocationWatcher'
import { Event } from './event'
import { watcherActor } from './watcherActor'

interface Context {
  point: GeoPoint | null
  errorMessage: string
  watcherRef?: any
}

export function returnGeolocationMachine() {
  return createMachine<Context, Event>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QwPYBsUGMCGAXAligHYB0+R+uAxANoAMAuoqAA4qyWFHMgAeiARgBMANhIBmABwB2AKx1JAFkUClI2SIA0IAJ6ChATgmThsmfIHTxdEQF9b21BhwFiZCtRoCmSEGw6u3L78CMJiUnIKyqqK6lq6iJLiJMriciLSAuIqVkL2jmDoWHhcJDhEAKqwYAAyxYFUFQDKAKIA+jUA8gDCAIIAKgCSnQBy9D6s7JzEPCGyQtp6CEI2JEppsgYiiuLbsvkgTvWlAK4cRFB1LlxUACKj-W3N7V19Q6PjPP7TQaBzUmtTBsrAoBItEHJFCRTHQVJt4YoDAcjtc3GdyJdjsRGq0Oj0BsMxowvlNArNEtJwQgMrISCIbEIkuIhJY5AJkYVnCU0edMaiiFRXgTRm1egA1XqDGq9ABCNRan183zJwQpVPmAgkkRWIkkklEQmkHKK-JI6IuV25AqF7xGbRaACUHZ0HYrJgEuOSEDIqdJRCRYWlJBpGZtpEiHIdOVjSOa+VaSGAAE5JlBJ2jEpWkz2qhCyAEmIT5uQgkxUySwkgh2Q7Ax0Q3SRv2SNEFAQOA8FFWkkema5gC0Bip-dpBjH44nE-Exq5gXclB7Py9igWCW9kgD5hsAjHAj2RsjXbns64FSI2AAbth8GhsAAjNBgRcqv6IRlGGE7uRjuh+8uamk6DoGtmSUAxGRnGMymwSpqktF8-GzPtXwQRsoV1LIFGkJJFCseIlhZIRoT9eRJAMWRsLHdRINNON4Jzd0l1zcQhzXawN2rARfwMaRgO2OxD2jWjeXotwMGwCAMWfBi+EQcQBFpYMgNUcQNnzAxxCpEQWKrBlsKSbYBGEGiEzoqDL2vW8HyfLNe1+WSEHkxT5DoFS1JYzS13zZJgxEQ0WV4oszBMuczNNZNUyTaTkIc3YiIokxcJMbCazBNdG1pLi-LrRR5BXWFm1sIA */
      id: 'geolocation',
      initial: 'idle',
      context: {
        point: null,
        errorMessage: '',
      },
      // start in idle, and when asked to use location try and init then
      on: {
        USE_LOCATION: 'init',
        DONT_USE_LOCATION: {
          target: 'idle',
          actions: ['resetError', 'clearPoint'],
        },
      },
      states: {
        idle: {},
        init: {
          always: [
            {
              cond: 'isGeolocationAvailable',
              target: 'usingLocation',
            },
            { target: 'locationUnavailable' },
          ],
        },
        locationUnavailable: {},
        // TODO: a simple promise to get location rather than a watcher
        usingLocation: {
          initial: 'loading',
          entry: [
            assign({
              watcherRef: (_) => spawn(watcherActor),
            }),
            'resetError',
          ],
          exit: [(ctx) => ctx.watcherRef.stop(), 'clearPoint'],
          on: {
            USE_LOCATION: '.loading',
            LOCATION_AVAILABLE: {
              actions: [
                assign({
                  point: (_, evt) => evt.point,
                }),
                (ctx) => ctx.watcherRef.stop(),
              ],
              target: '.available',
            },
            LOCATION_ERROR: {
              actions: [
                assign({
                  errorMessage: (_, evt) => evt.message,
                }),
                (_, { data }: any) =>
                  reportError(data, { machine: 'geolocation' }),
                'resetPoint',
              ],
              target: '.error',
            },
          },
          activities: [],
          states: {
            loading: {
              entry: ['resetError', 'clearPoint'],
            },
            available: {
              entry: ['resetError'],
            },
            error: {
              always: '#geolocation.idle',
            },
          },
        },
      },
    },
    {
      actions: {
        resetError: assign({
          errorMessage: (_) => '',
        }),
        clearPoint: assign({
          point: (_) => null,
        }),
      },
      guards: {
        isGeolocationAvailable: () => LocationWatcher.isGeolocationAvailable(),
      },
    },
  )
}

export type MachineInterpreter = Interpreter<Context, any, Event>
export type MachineState = State<Context, Event>
