
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

interface AccommodationType {
  name: string
  to: { facet?: string; category: string }
  iconUrl?: string
}

@Component
export default class AccommodationTypes extends Vue {
  @Prop({ required: true })
    categoriesData: AccommodationType[]

  get modifiedLinkFilters() {
    return { hierarchyPath: undefined, facets: undefined, page: undefined }
  }
}
