import { GeoPoint } from '~/utility/geo/GeoLocation'
import { GEOLOCATION_OPTIONS } from './config'

export class LocationWatcher {
  private handlerId?: number

  constructor(private handlers: Handlers) {}

  startWatching() {
    if (this.handlerId === undefined) {
      this.handlerId = window.navigator.geolocation.watchPosition(
        coordinatesHandlerConverter(this.handlers.success),
        this.handlers.error,
        GEOLOCATION_OPTIONS,
      )
    }
  }

  stopWatching() {
    if (this.handlerId !== undefined) {
      window.navigator.geolocation.clearWatch(this.handlerId)
      this.handlerId = undefined
    }
  }

  static isGeolocationAvailable() {
    return (
      typeof (window as any) !== 'undefined' && !!window.navigator.geolocation
    )
  }
}

const coordinatesHandlerConverter =
  (pointHandler: (point: GeoPoint) => void) =>
    (position: GeolocationPosition) =>
      pointHandler(positionToPoint(position))

function positionToPoint(position: GeolocationPosition): GeoPoint {
  return {
    lat: parseFloat(position.coords.latitude.toFixed(4)),
    lng: parseFloat(position.coords.longitude.toFixed(4)),
    isEmptyPoint: false,
  }
}

interface Handlers {
  success(point: GeoPoint)
  error(positionError: GeolocationPositionError)
}
