
import { isEqual } from 'lodash'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { interpret } from 'xstate'
import { GeoPoint } from '~/utility/geo/GeoLocation'
import {
  MachineInterpreter,
  MachineState,
  returnGeolocationMachine,
} from '../machine/machine'

@Component
export default class GeolocationCheckbox extends Vue {
  interpreter: MachineInterpreter | null = null
  state: MachineState | null = null

  get error() {
    return this.state?.context.errorMessage || ''
  }

  get errorLocalized(): string {
    return this.$i18n.t(this.error).toString()
  }

  get geolocationServiceAvailable() {
    return this.state?.matches('usingLocation.available') || false
  }

  @Prop()
    currentPoint?: GeoPoint | null

  get point(): GeoPoint | null {
    return this.state?.context.point || null
  }

  @Watch('point')
  onPointChanged(newPoint: GeoPoint, oldPoint: GeoPoint) {
    if (!isEqual(newPoint, oldPoint)) this.$emit('pointChange', newPoint)
  }

  mounted() {
    this.interpreter = interpret(returnGeolocationMachine())
      .onTransition((state) => {
        this.state = state
      })
      .start()
  }

  beforeDestroy() {
    if (this.interpreter) {
      this.interpreter.stop()
      this.interpreter = null
    }
  }

  // checkbox calls this when changed
  checkboxChanged(use: boolean): void {
    if (use) {
      // starts a request for a new location
      this.interpreter?.send({ type: 'USE_LOCATION' })
    } else {
      // cancels any request and existing point
      this.interpreter?.send({ type: 'DONT_USE_LOCATION' })
      // special case, when it has no point (Because it was SSR, then emit a null)
      if (!this.point) {
        this.$emit('pointChange', null)
      }
    }
  }
}
