const geolocationErrorCodes = {
  1: "We can't see your current location. Turn on location in your device's settings to use this feature.",
  2: "Position unavailable. Please ensure 'Location Services' is enabled in your device settings.",
  3: 'Location request timed out. Please try again.',
}

export function getLocationErrorMessage(error: GeolocationPositionError) {
  const msg = geolocationErrorCodes[error.code]
  if (msg) return msg
  else return 'Unknown geolocation error'
}
