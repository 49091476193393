import { GeoPoint } from '~/utility/geo/GeoLocation'
import { LocationWatcher } from '../location-watcher/LocationWatcher'
import { Event } from './event'
import { getLocationErrorMessage } from './getLocationErrorMessage'

export function watcherActor(callback: (evt: Event) => void) {
  const error = (err: GeolocationPositionError) =>
    callback({ type: 'LOCATION_ERROR', message: getLocationErrorMessage(err) })
  const success = (point: GeoPoint) =>
    callback({ type: 'LOCATION_AVAILABLE', point })

  const watcher = new LocationWatcher({
    success,
    error,
  })
  watcher.startWatching()
  return () => watcher.stopWatching()
}
