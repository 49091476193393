import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=062527f6&scoped=true"
import script from "./ArticleList.vue?vue&type=script&lang=ts"
export * from "./ArticleList.vue?vue&type=script&lang=ts"
import style0 from "./ArticleList.vue?vue&type=style&index=0&id=062527f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062527f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuLink: require('/app/apps/pu-links/pu-link/PuLink.vue').default})
