import { render, staticRenderFns } from "./PopularSearches.vue?vue&type=template&id=1f9ee376&scoped=true"
import script from "./PopularSearches.vue?vue&type=script&lang=ts"
export * from "./PopularSearches.vue?vue&type=script&lang=ts"
import style0 from "./PopularSearches.vue?vue&type=style&index=0&id=1f9ee376&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f9ee376",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuSearchLink: require('/app/apps/pu-links/link-search/PuSearchLink.vue').default})
