
import { popularSearchesData } from '~/json/popularSearchesData'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component<PopularSearches>({
  head: function () {
    return this.head()
  },
})
export default class PopularSearches extends Vue {
  popularSearchesData = popularSearchesData

  @Prop({ default: true })
    withImage: boolean

  @Prop({})
    popularSearches?: {
    to: any
    label: string
    imgUrl: string
    isLCP: boolean
    displayOrder: Number
  }[]

  head() {
    if (!this.withImage) return {}
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/popular-searches/wales.jpg'),
        },
      ],
    }
  }

  get columnCount() {
    return Math.round(this.popularSearchesData.length / 2)
  }

  isLazyImg(index: number): boolean {
    return index > 5
  }
}
